.nav-link {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.details-breadcrumb {
  --bs-breadcrumb-divider: '>';
}

.AccountMenu {
  margin-right: 20px;
}

@media screen and (max-width: 680px) {
  .breadcrumb {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .breadcrumb {
    font-size: 12px;
  }
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0);
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler:focus > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(20,220,160, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.login-btn {
  background-color: var(--s-bg-2);
  padding: 5px 20px !important;
  border-radius: 10px;
  color: white !important;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

/* ICONS */

.icon-navbar {
  margin-right: 8px !important;
}

.color-1 {
  color: var(--s-color-1);
}

.color-2 {
  color: var(--s-color-2);
}

/* Home Icon */

.gg-home-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  border-radius: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: -2px
}

.gg-home-alt::after,
.gg-home-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}

.gg-home-alt::before {
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-left-radius: 4px;
  transform: rotate(45deg);
  top: -5px;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  left: 0
}

.gg-home-alt::after {
  width: 6px;
  height: 10px;
  background: currentColor;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  left: 4px;
  bottom: -2px
} 

/* Movie Icon */

.gg-film,
.gg-film::after {
 display: block;
 box-sizing: border-box;
 border-radius: 3px
}

.gg-film {
 border: 2px solid;
 position: relative;
 transform: scale(var(--ggs,1));
 width: 22px;
 height: 18px
}

.gg-film::after {
 content: "";
 position: absolute;
 width: 2px;
 height: 2px;
 background: currentColor;
 left: 2px;
 top: 2px;
 box-shadow:
 0 4px 0,
 0 8px 0,
 12px 0 0,
 12px 4px 0,
 12px 8px 0
} 

/* Series Icon */

 .gg-tv {
 box-sizing: border-box;
 position: relative;
 display: block;
 transform: scale(var(--ggs,1));
 width: 20px;
 height: 15px;
 border: 2px solid;
 border-radius: 2px
}

.gg-tv::after,
.gg-tv::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute
}

.gg-tv::before {
 border-left: 2px solid;
 border-bottom: 2px solid;
 border-bottom-left-radius: 2px;
 top: -6px;
 left: 3px;
 transform: rotate(-45deg);
 width: 6px;
 height: 5px
}

.gg-tv::after {
 width: 12px;
 height: 2px;
 background: currentColor;
 border-radius: 10px;
 bottom: -6px;
 right: 2px
} 

/* Search Icon */

 .gg-search {
 box-sizing: border-box;
 position: relative;
 display: block;
 transform: scale(var(--ggs,1));
 width: 16px;
 height: 16px;
 border: 2px solid;
 border-radius: 100%;
 margin-left: -4px;
 margin-top: -4px
}

.gg-search::after {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute;
 border-radius: 3px;
 width: 2px;
 height: 8px;
 background: currentColor;
 transform: rotate(-45deg);
 top: 10px;
 left: 12px
} 

/* Log In Icon */

 .gg-log-in {
 box-sizing: border-box;
 position: relative;
 display: block;
 transform: scale(var(--ggs,1));
 width: 6px;
 height: 16px;
 border: 2px solid;
 border-left: 0;
 border-top-right-radius: 2px;
 border-bottom-right-radius: 2px;
 margin-right: 10px
}

.gg-log-in::after,
.gg-log-in::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute
}

.gg-log-in::after {
 border-top: 2px solid;
 border-right: 2px solid;
 transform: rotate(45deg);
 width: 8px;
 height: 8px;
 left: -8px;
 bottom: 2px
}

.gg-log-in::before {
 border-radius: 3px;
 width: 10px;
 height: 2px;
 background: currentColor;
 left: -11px;
 bottom: 5px
} 

/* Settings Icons */

.gg-options {
 box-sizing: border-box;
 position: relative;
 display: block;
 transform: scale(var(--ggs,1));
 width: 10px;
 height: 2px;
 box-shadow:
 -3px 4px 0 0,
 3px -4px 0 0
}

.gg-options::after,
.gg-options::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute;
 width: 8px;
 height: 8px;
 border: 2px solid;
 border-radius: 100%
}

.gg-options::before {
 top: -7px;
 left: -4px
}

.gg-options::after {
 bottom: -7px;
 right: -4px
} 

/* Collection Icon */

.gg-stack,
.gg-stack::after,
.gg-stack::before {
 display: block;
 box-sizing: border-box;
 width: 14px;
 height: 14px;
 border: 2px solid
}

.gg-stack {
 margin-right: 8px;
 margin-top: 8px;
 transform: scale(var(--ggs,1));
 position: relative
}

.gg-stack::after,
.gg-stack::before {
 content: "";
 position: absolute;
 border-left: 0;
 border-bottom: 0;
 right: -5px;
 top: -5px
}

.gg-stack::before {
 right: -8px;
 top: -8px
} 