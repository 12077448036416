@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;400&display=swap);
figure {
  margin: 0;
}

.carousel__category {
  width: 90%;
  margin: 3% auto;
}

.carousel__category__title {
  font-weight: 500;
  font-size: 1.8em;
}

.sslider-landscape .swiper-button-prev,.sslider-landscape .swiper-button-next {
  top: 40%;
}

.sslider-portrait .swiper-button-prev,.sslider-portrait .swiper-button-next {
  top: 47%;
}

.swiper-button-prev, .swiper-button-next {
  padding: 25px;
  border-radius: 50%;
  font-size: 10px;
  height: 15px;
  width: 15px;
  background-color: rgba(20, 20, 20, 0.7);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

@media only screen and (max-width: 740px) {
  .hide-navigation > .swiper-button-prev {
    display: none;
  }
  .hide-navigation > .swiper-button-next {
      display: none;
  }
}

.carousel__item__title {
  font-size: 0.875rem;
  font-weight: 400;
  max-width: 95%;
  margin: auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.season-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.season-single {
    margin-left: 10px;
}
.Login-form {
    height: 100%;
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.Login-form-container {
    background-color: var(--s-bg-2);
    padding: 10px 20px 20px 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 50px;
    border-radius: 10px;
}
.loader-container {
  margin-top: 10%;
  display: flex;
  justify-content: center;
}

.loader-container .loader {
  height: 6rem;
  width: 6rem;
  position: relative;
}
.loader-container .loader .square {
  height: 50%;
  width: 50%;
  position: absolute;
  background: var(--s-color-1);
}
.loader-container .loader .one {
  top: 0;
  left: 0;
  -webkit-animation: animate_one 5s infinite ease;
          animation: animate_one 5s infinite ease;
  background: var(--s-color-1);
}
.loader-container .loader .two {
  top: 50%;
  left: 50%;
  -webkit-animation: animate_two 5s infinite ease;
          animation: animate_two 5s infinite ease;
  background: var(--s-white);
}

@-webkit-keyframes animate_one {
  0%,
  100% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }
  12.5% {
    left: 0;
    width: 100%;
  }
  25% {
    top: 0;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  37.5% {
    top: 0;
    height: 100%;
  }
  50% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  62.5% {
    left: 0;
    width: 100%;
  }
  75% {
    top: 50%;
    width: 50%;
    height: 50%;
  }
  87.5% {
    top: 0;
    left: 0;
    height: 100%;
  }
}

@keyframes animate_one {
  0%,
  100% {
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
  }
  12.5% {
    left: 0;
    width: 100%;
  }
  25% {
    top: 0;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  37.5% {
    top: 0;
    height: 100%;
  }
  50% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  62.5% {
    left: 0;
    width: 100%;
  }
  75% {
    top: 50%;
    width: 50%;
    height: 50%;
  }
  87.5% {
    top: 0;
    left: 0;
    height: 100%;
  }
}

@-webkit-keyframes animate_two {
  0%,
  100% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  12.5% {
    left: 0;
    width: 100%;
  }
  25% {
    top: 50%;
    width: 50%;
    height: 50%;
  }
  37.5% {
    top: 0;
    height: 100%;
  }
  50% {
    left: 0;
    width: 50%;
    height: 50%;
  }
  62.5% {
    top: 0;
    left: 0;
    width: 100%;
  }
  75% {
    left: 50%;
    width: 50%;
    height: 50%;
  }
  87.5% {
    top: 0;
    height: 100%;
  }
}

@keyframes animate_two {
  0%,
  100% {
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  12.5% {
    left: 0;
    width: 100%;
  }
  25% {
    top: 50%;
    width: 50%;
    height: 50%;
  }
  37.5% {
    top: 0;
    height: 100%;
  }
  50% {
    left: 0;
    width: 50%;
    height: 50%;
  }
  62.5% {
    top: 0;
    left: 0;
    width: 100%;
  }
  75% {
    left: 50%;
    width: 50%;
    height: 50%;
  }
  87.5% {
    top: 0;
    height: 100%;
  }
}
.nav-link {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.details-breadcrumb {
  --bs-breadcrumb-divider: '>';
}

.AccountMenu {
  margin-right: 20px;
}

@media screen and (max-width: 680px) {
  .breadcrumb {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .breadcrumb {
    font-size: 12px;
  }
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0);
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler:focus > .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(20,220,160, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.login-btn {
  background-color: var(--s-bg-2);
  padding: 5px 20px !important;
  border-radius: 10px;
  color: white !important;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

/* ICONS */

.icon-navbar {
  margin-right: 8px !important;
}

.color-1 {
  color: var(--s-color-1);
}

.color-2 {
  color: var(--s-color-2);
}

/* Home Icon */

.gg-home-alt {
  box-sizing: border-box;
  position: relative;
  display: block;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform: scale(var(--ggs,1));
          transform: scale(var(--ggs,1));
  width: 18px;
  height: 14px;
  border: 2px solid;
  border-top: 0;
  border-radius: 2px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: -2px
}

.gg-home-alt::after,
.gg-home-alt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}

.gg-home-alt::before {
  border-top: 2px solid;
  border-left: 2px solid;
  border-top-left-radius: 4px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: -5px;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  left: 0
}

.gg-home-alt::after {
  width: 6px;
  height: 10px;
  background: currentColor;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  left: 4px;
  bottom: -2px
} 

/* Movie Icon */

.gg-film,
.gg-film::after {
 display: block;
 box-sizing: border-box;
 border-radius: 3px
}

.gg-film {
 border: 2px solid;
 position: relative;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 width: 22px;
 height: 18px
}

.gg-film::after {
 content: "";
 position: absolute;
 width: 2px;
 height: 2px;
 background: currentColor;
 left: 2px;
 top: 2px;
 box-shadow:
 0 4px 0,
 0 8px 0,
 12px 0 0,
 12px 4px 0,
 12px 8px 0
} 

/* Series Icon */

 .gg-tv {
 box-sizing: border-box;
 position: relative;
 display: block;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 width: 20px;
 height: 15px;
 border: 2px solid;
 border-radius: 2px
}

.gg-tv::after,
.gg-tv::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute
}

.gg-tv::before {
 border-left: 2px solid;
 border-bottom: 2px solid;
 border-bottom-left-radius: 2px;
 top: -6px;
 left: 3px;
 -webkit-transform: rotate(-45deg);
         transform: rotate(-45deg);
 width: 6px;
 height: 5px
}

.gg-tv::after {
 width: 12px;
 height: 2px;
 background: currentColor;
 border-radius: 10px;
 bottom: -6px;
 right: 2px
} 

/* Search Icon */

 .gg-search {
 box-sizing: border-box;
 position: relative;
 display: block;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 width: 16px;
 height: 16px;
 border: 2px solid;
 border-radius: 100%;
 margin-left: -4px;
 margin-top: -4px
}

.gg-search::after {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute;
 border-radius: 3px;
 width: 2px;
 height: 8px;
 background: currentColor;
 -webkit-transform: rotate(-45deg);
         transform: rotate(-45deg);
 top: 10px;
 left: 12px
} 

/* Log In Icon */

 .gg-log-in {
 box-sizing: border-box;
 position: relative;
 display: block;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 width: 6px;
 height: 16px;
 border: 2px solid;
 border-left: 0;
 border-top-right-radius: 2px;
 border-bottom-right-radius: 2px;
 margin-right: 10px
}

.gg-log-in::after,
.gg-log-in::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute
}

.gg-log-in::after {
 border-top: 2px solid;
 border-right: 2px solid;
 -webkit-transform: rotate(45deg);
         transform: rotate(45deg);
 width: 8px;
 height: 8px;
 left: -8px;
 bottom: 2px
}

.gg-log-in::before {
 border-radius: 3px;
 width: 10px;
 height: 2px;
 background: currentColor;
 left: -11px;
 bottom: 5px
} 

/* Settings Icons */

.gg-options {
 box-sizing: border-box;
 position: relative;
 display: block;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 width: 10px;
 height: 2px;
 box-shadow:
 -3px 4px 0 0,
 3px -4px 0 0
}

.gg-options::after,
.gg-options::before {
 content: "";
 display: block;
 box-sizing: border-box;
 position: absolute;
 width: 8px;
 height: 8px;
 border: 2px solid;
 border-radius: 100%
}

.gg-options::before {
 top: -7px;
 left: -4px
}

.gg-options::after {
 bottom: -7px;
 right: -4px
} 

/* Collection Icon */

.gg-stack,
.gg-stack::after,
.gg-stack::before {
 display: block;
 box-sizing: border-box;
 width: 14px;
 height: 14px;
 border: 2px solid
}

.gg-stack {
 margin-right: 8px;
 margin-top: 8px;
 -webkit-transform: scale(1);
         transform: scale(1);
 -webkit-transform: scale(var(--ggs,1));
         transform: scale(var(--ggs,1));
 position: relative
}

.gg-stack::after,
.gg-stack::before {
 content: "";
 position: absolute;
 border-left: 0;
 border-bottom: 0;
 right: -5px;
 top: -5px
}

.gg-stack::before {
 right: -8px;
 top: -8px
} 
.nf {
  display: table;
  width: 100%;
  height: 90vh;
  transition: all 0.6s;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  -webkit-animation: type 0.5s alternate infinite;
          animation: type 0.5s alternate infinite;
}

@-webkit-keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.search-wrapper {
    padding: 20px;
}

.sugoi-search-bar span {
    background-color: var(--s-bg-2);
    border: none;
    width: 50px;
}

.sugoi-search-bar span .gg-search {
    margin-left: auto;
    margin-right: auto;
}

.sugoi-search-bar input {
    background-color: var(--s-bg-2);
    border: none;
    height: 50px;
    color: var(--s-white) !important;
}

.sugoi-search-bar input::-webkit-input-placeholder {
    color: var(--s-white-8);
}

.sugoi-search-bar input:-ms-input-placeholder {
    color: var(--s-white-8);
}

.sugoi-search-bar input::placeholder {
    color: var(--s-white-8);
}

.sugoi-search-bar input:focus {
    background-color: #14426d;
}
.SortMenu {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  grid-gap: 10px;
  gap: 10px;
}

.sort__container {
  margin: 10px;
  width: 210px;
  max-width: 43%;
}

figure {
  margin: 0;
}

.tile__category {
  width: 90%;
  margin: 3% auto;
}

.tile__category__title {
  font-weight: 500;
  font-size: 1.8em;
}

.card-img {
  position: relative;
  cursor: pointer;
}

.card-img img{
  opacity: 1;
  display: block;
  width: 100%;
  object-fit: cover;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.card-img .middle .bi {
  font-size: 50px;
}

.round-button {
box-sizing: border-box;
display:block;
width:80px;
height:80px;
padding-top: 15px;
padding-left: 3px;
line-height: 20px;
border-radius: 50%;
color:#f5f5f5;
text-align:center;
text-decoration:none;
background-color: rgba(0, 0, 0, 0.7);
font-size:20px;
font-weight:bold;
transition: all 0.3s ease;
}

.round-button:hover {
color: var(--s-color-1);
}

.middle {
transition: .5s ease;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
opacity: 0;
}

.card-img:hover .middle {
opacity: 1;
}

.card-img:hover > .card-image {
opacity: 0.7;
}


.plyr__component {
  width: 1200px;
  max-width: 92vw;
  margin: 50px auto 0;
}

.item-details-content {
  margin-top: -65px;
	margin-bottom: 65px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.item-page-logo {
  max-width: 200px;
}

.item__details__text {
	position: relative;
  margin-left: 20px;
  margin-top: 20px;
}

.item__details__text p {
	color: #b7b7b7;
	font-size: 14px;
	line-height: 30px;
}

.item-details-content-poster {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
	border-radius: 5px;
	position: relative;
  margin-top: 100px;
}

.item-details-content-poster img {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
}

.item-details-content-poster-info {
  position: inherit;
}

.item-details-content-poster-info .comment-info {
	font-size: 13px;
	color: #ffffff;
	background: #3d3d3d;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 4px;
	position: absolute;
	left: 25px;
	bottom: 25px;
}

.item-details-content-poster-info .rating-info {
	color: #ffffff;
	display: inline-block;
	padding: 5px;
	position: absolute;
	right: 0;
	bottom: 0;
  width: 60px;
  height: 60px;
}

.item__details__title h3 {
	color: #ffffff;
	font-weight: 700;
  margin-top: 20px;
	margin-bottom: 13px;
}

.item__details__title p {
	color: #ffffff;
  font-size: 12px;
}

.item__details__title span {
	font-size: 15px;
	color: #b7b7b7;
	display: block;
}

.item__details__rating .rating i {
	font-size: 24px;
	color: #e89f12;
	display: inline-block;
}

.item__details__rating span {
	display: block;
	font-size: 18px;
	color: #b7b7b7;
}

.item__details__widget {
	margin-bottom: 15px;
}

.item__details__widget ul {
	margin-bottom: 20px;
}

.item__details__widget ul li {
	list-style: none;
	font-size: 15px;
	color: #ffffff;
	line-height: 30px;
	position: relative;
	padding-left: 18px;
}

.item__details__widget ul li:before {
	position: absolute;
	left: 0;
	top: 12px;
	height: 6px;
	width: 6px;
	background: #b7b7b7;
	content: "";
}

.item__details__widget ul li span {
	color: #b7b7b7;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: inline-block;
}

.item-details-btn {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.item-details-btn-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item-details-btn-bottom .s-btn-2:last-child {
  margin-right:0px;
}

.item__details__review {
	margin-bottom: 55px;
}
.backdrop-img {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.backdrop-img img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .item-details-content-poster {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  .item-details-content {
    margin-top: -200px;
  }
  .item-details-btn .follow-btn,.item-details-btn .watch-btn {
    margin: 20px 0px;
  }
}

@media only screen and (max-width: 761px) {
  .item-details-content-poster {
    width: 40%;
  }
  .item-details-content {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 494px) {
  .item__details__text {
    margin-top: 100px;
  }
  .item-details-btn {
    display: block;
  }
  .item-details-btn a {
    width: 100%;
  }
  .item-details-btn .follow-btn,.item-details-btn .watch-btn {
    margin: 10px 0;
  }
  .item-details-content-poster .comment-info{
    left: 5px;
    bottom: 5px;
  }
  .item-details-content-poster .rating-info {
    right: 5px;
    bottom: 5px;
  }
}

@media only screen and (max-width: 361px) {
  .item-details-content-poster {
    width: 60%;
  }
}

@media only screen and (min-width: 992px) {
  .backdrop-img {
    height: 350px;
    width: 100%;
    overflow: hidden;
  }
  .backdrop-img img {
    width: 100%;
  }
  .item__details__text {
    margin-top: 100px;
  }
  .item-details-btn {
    display: block;
  }
  .item-details-btn a {
    width: 100%;
  }
}

.modal-content {
  border: none;
  background-color: var(--s-bg-1);
}

.trailer-modal-header {
  border-bottom: none;
  background-color: #fff;
}

.trailer-modal-body {
  background-color: var(--s-bg-1);
  overflow: hidden;
  border-radius: 0px 0px 5px 5px;
}

.card-info-episode {
  position: absolute;
  padding: 5px;
}

/* .plyr__progress {
  position: absolute !important;
  width: calc(100% - 60px);
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0;
  right: 0;
  bottom: 45px;
}

.plyr__control--overlaid svg{
  height: 50px;
  padding: 10px;
  width: 50px;
}

.plyr__control:hover {
  background-color: var(--s-black);
}

.plyr__control--overlaid {
  background-color: #000000ce !important;
}

.plyr__control[role=menuitemradio]:hover {
  background-color: var(--s-color-1) !important;
  color: #3d3d3d;
}

.plyr__control[role=menuitem]:hover {
  background-color: var(--s-color-1) !important;
  color: #3d3d3d;
}

.plyr__control--back:hover {
  color: #3d3d3d !important;
}
*/


.s-btn-1 {
  color: #000000;
  background: var(--s-color-1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  letter-spacing: 2px;
  text-align: center;
  padding: 14px 20px;
  border-radius: 4px;
  border: none;
  margin: 11px 0px;
  width: 100%;
}

.s-btn-1:disabled {
  color: #2b2b2b;
  background: var(--s-color-1-2);
}

.s-btn-2 {
	color: #ffffff;
	background: #38383880;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  font-size: 20px;
	letter-spacing: 2px;
  text-align: center;
	padding: 14px 20px;
	border-radius: 4px;
  border: none;
  width: 100%;
  margin-right: 15px;
}

.plyr__menu__value:hover {
    color: black;
}

.bg-color-white {
    background-color: var(--s-white);
}

.color-black {
    color: var(--s-black);
}

.main-bottom-navigation {
    display: none;
}

.dplayer-video-wrap {
  background: rgb(19, 19, 19);
}

.plyr-playlist-wrapper ul::-webkit-scrollbar {
  background-color: #202324;
  color: #aba499;
  width: 6px;
}

.plyr-playlist-wrapper ul::-webkit-scrollbar-track {
  background-color: #454a4d;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}

.plyr-playlist-wrapper ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background: var(--plyr-color-main);
}

.plyr-playlist-wrapper {
  background: rgba(0, 0, 0, 0.8);
  border-width: 0 4px 4px 4px;
  border-color: black;
  border-style: solid;
  border-radius: 0 0 12px 12px;
  position: relative;
  padding: 0.5em 0.5em 0.5em 0.25em;
}

.plyr-playlist-wrapper .plyr-playlist {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  margin-top: 0;
  padding: 6px 6px 0px 6px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 8px 0px inset;
}

.plyr-playlist-wrapper ul {
  padding: 0;
  margin: 0;
  max-height: 40vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.plyr-playlist-wrapper ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.plyr-playlist-wrapper .plyr-playlist li {
  list-style: none;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 5px;
  margin-bottom: 3px;
  font-size: 90%;
}

.plyr-playlist-wrapper .plyr-playlist li.pls-playing,
.plyr-playlist-wrapper .plyr-playlist li:hover {
  color: var(--plyr-color-main);
  background-color: rgba(255, 255, 255, 0.09);
}
.plyr-playlist-wrapper .plyr-playlist li.pls-playing a {
  color: var(--plyr-color-main);
}

.plyr-playlist-wrapper .plyr-playlist li a {
  text-decoration: none;
  font-family: arial;
  color: #c9c9c9;
  font-size: 90%;
  vertical-align: middle;
}

.plyr-playlist-wrapper .plyr-playlist li div {
  display: block;
  outline: none;
  padding: 0.5em 0.25em 0.5em 0.75em;
  vertical-align: middle;
}

.plyr-playlist-wrapper .plyr-playlist li:last-child {
  border-radius: 0 0 6px 6px;
}

.plyr-playlist-wrapper .plyr-playlist li:last-child a {
  border-bottom: 0;
}

.plyr-playlist li a:hover,
.plyr-playlist li a:focus,
.plyr-playlist li a:active {
  color: var(--plyr-color-main);
}

.plyr-miniposter {
  width: auto;
  height: 22px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  margin-right: 10px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

.plyr-miniposter:hover {
  cursor: zoom-in;
}

.plyr-type-playlist plyr-playlist a.plyr-playlist-item-remove {
  float: right;
  margin-right: 15px;
  background-color: transparent;
}

.plyr-prev {
  margin-right: 0 !important;
}
.plyr-next {
  margin-left: 0 !important;
}
.plyr__controls [data-plyr="play"] {
  margin-left: 0 !important;
}

.info__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1500px;
  max-width: 90vw;
  margin: 50px auto;
}

.info__left {
  margin-right: 20px;
  max-width: 70%;
}

.info__right {
  margin-left: 20px;
  max-width: 30%;
}

.vote__container {
  margin-top: 20px;
  width: 240px;
}

.info__release {
  display: flex;
  margin-top: 5px;
  margin-left: 4px;
}

.video_button_group {
  background-color: var(--s-bg-2);
  padding: 10px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
}

button .bi {
  margin-right: 5px;
}

.s-btn-2 .bi {
  margin-right: 0;
}

.info__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.info__button {
  padding: 0 5px;
}

.info__buttons2 {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}
.info__button2 {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 5px 5px 0 0;
}

.info__genres {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.info__genre {
  cursor: pointer !important;
  margin: 5px 0px 5px 10px;
}

.info__poster {
  border-radius: 10px;
  width: 350px;
  max-width: 100%;
}

.info__backdrop {
  border-radius: 10px;
  width: 0;
  display: none;
}

.info__title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info__overview {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.file__info {
  width: 1200px;
  max-width: 92vw;
  display: flex;
  margin: 30px auto;
  text-align: center;
  margin-bottom: 15px;
  border-radius: 12px;
}

.season-btn {
  margin: 10px
}

.s-btn-1 {
  color: #000000;
  background: var(--s-color-1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  letter-spacing: 2px;
  text-align: center;
  padding: 14px 20px;
  border-radius: 4px;
  border: none;
  margin: 11px 0px;
  width: 100%;
}

.s-btn-1:disabled {
  color: #2b2b2b;
  background: var(--s-color-1-2);
}

.s-btn-2 {
	color: #ffffff;
	background: #003f4e80;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  font-size: 20px;
	letter-spacing: 2px;
  text-align: center;
	padding: 14px 20px;
	border-radius: 4px;
  border: none;
  width: 100%;
  margin-right: 15px;
}

@media screen and (max-width: 290px) {
  .vote__container {
    margin: 0;
    display: none;
  }
  .info__left {
    display: none;
    width: 0;
    margin: 0;
  }
}

@media screen and (max-width: 556px) {
  .info__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .info__button {
    width: 100%;
    margin-bottom: 10px;
  }
  .info__button:first-child {
    margin-top: 5px;
  }
  .info__button:last-child {
    margin-bottom: 5px;
  }
  .info__button .btn-primary {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .info__container {
    flex-direction: column;
  }
  .info__left {
    width: 90vw;
    max-width: 90vw;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .info__poster {
    display: none;
    width: 0;
  }
  .info__backdrop {
    display: inherit;
    width: 90vw;
    max-width: 90vw;
    margin-bottom: 15px;
  }
  .info__right {
    max-width: 90vw;
    margin-left: 0;
  }
  .plyr-playlist-wrapper ul {
    max-height: 25vh;
  }
}

.dplayer-controller .dplayer-bar-wrap .dplayer-bar .dplayer-loaded {
  background: #888 !important;
}
:root {
  --s-color-1: #14dca0;
  --s-color-1-7: #28daa4;
  --s-color-1-5: #3cd4a7;
  --s-color-1-2: #3baf8a;
  --s-color-2: #0084ff;
  --s-color-2-7: #1482dc;
  --s-color-2-5: #2585d4;
  --s-color-2-2: #3385c9;
  --s-color-3: #3e00cf;
  --s-color-3-7: #fc7d7d;
  --s-white: #ffffff;
  --s-white-9: #ffffffe6;
  --s-white-8: #ffffffcc;
  --s-white-7: #ffffffb3;
  --s-white-6: #ffffff99;
  --s-white-5: #ffffff80;
  --s-white-4: #ffffff66;
  --s-white-3: #ffffff4d;
  --s-white-2: #ffffff33;
  --s-white-1: #ffffff1a;
  --s-black: #000000;
  --s-transparent: #00000000;
  --s-bg-1: #071018;
  --s-bg-2: #162B45;
  --s-bg-3: #232f57;
  --plyr-color-main: var(--s-color-1);
  --swiper-theme-color: var(--s-white) !important;
  --swiper-navigation-size: 20px !important;
}

body {
  margin: 0;
  background-color: #111;
  font-family: 'Readex Pro', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  position: relative;
  min-height: 100vh;
}

* {
  margin: 0;
  scroll-behavior: smooth;
  scrollbar-color: #202324 #454a4d;
}

h4 {
  color: #ffffffcc;
  color: var(--s-white-8);
}

a {
  text-decoration: none;
  color: #14dca0;
  color: var(--s-color-1);
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
  background: #162B45;
  background: var(--s-bg-2); 
}
  
::-webkit-scrollbar-thumb {
  background: #14dca0;
  background: var(--s-color-1); 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.no_decoration_link {
  text-decoration: inherit;
  color: inherit;
}

.Loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-container {
  margin: 0;
  margin: 0px 10%;
}

.video-top {
  display: flex;
  align-items: center;
  background-color: #162B45;
  background-color: var(--s-bg-2);
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
}

.video-title {
  font-size: 20px;
  margin-right: 20px;
}

.break {
  padding-left: 10px;
  padding-right: 10px;
  color: #14dca0;
  color: var(--s-color-1);
  font-size: 20px;
}

@media (max-width: 768px) {
  .video-title {
      font-size: 16px;
      width: 100%;
  }
  .video-description {
      font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .NewsMenu {
    margin: 0;
    display: none;
    width: 0;
    height: 0;
  }
}

@media screen and (max-width: 370px) {
  .ThemeMenu {
    margin: 0;
    display: none;
    width: 0;
    height: 0;
  }
}

#footer__container {
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer__text {
  margin-left: 10px;
}

.footer__github {
  display: flex;
  align-items: center;
  margin: 5px;
}

@media screen and (max-width: 320px) {
  #footer__container {
    display: none;
  }
}

@media screen and (max-width: 385px) {
  .footer__github {
    display: none;
  }
  .footer__text {
    margin: 0;
  }
}

@media screen and (max-width: 505px) {
  .gallery__select {
    display: none;
  }
}

