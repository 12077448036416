.Login-form {
    height: 100%;
    width: 90%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.Login-form-container {
    background-color: var(--s-bg-2);
    padding: 10px 20px 20px 20px;
    height: fit-content;
    margin-top: 50px;
    border-radius: 10px;
}