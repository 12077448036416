figure {
  margin: 0;
}

.tile__category {
  width: 90%;
  margin: 3% auto;
}

.tile__category__title {
  font-weight: 500;
  font-size: 1.8em;
}

.card-img {
  position: relative;
  cursor: pointer;
}

.card-img img{
  opacity: 1;
  display: block;
  width: 100%;
  object-fit: cover;
  transition: .5s ease;
  backface-visibility: hidden;
}

.card-img .middle .bi {
  font-size: 50px;
}

.round-button {
box-sizing: border-box;
display:block;
width:80px;
height:80px;
padding-top: 15px;
padding-left: 3px;
line-height: 20px;
border-radius: 50%;
color:#f5f5f5;
text-align:center;
text-decoration:none;
background-color: rgba(0, 0, 0, 0.7);
font-size:20px;
font-weight:bold;
transition: all 0.3s ease;
}

.round-button:hover {
color: var(--s-color-1);
}

.middle {
transition: .5s ease;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
opacity: 0;
}

.card-img:hover .middle {
opacity: 1;
}

.card-img:hover > .card-image {
opacity: 0.7;
}

